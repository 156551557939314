import { fetchPrettyPlayer, fetchPrettyPlayerList } from "./internal-utils/player-utils";
import * as licenseUtils from "./internal-utils/license-utils";
import * as orgUtils from "./internal-utils/org-utils";
import * as teamUtils from "./internal-utils/team-utils";
import { nmiSDK } from "./utils/nmiSDK";

export const player = {
  fetchPrettyPlayer,
  fetchPrettyPlayerList
};
export const nmi = nmiSDK;

export const team = teamUtils;
export const license = licenseUtils;
export const org = orgUtils;

// i18n certified - complete
